<template>
    <section class="side-bar">
        <div class="side-bar-header">
            <img style="border-radius:30%;padding-top: 33px"   class="logo" src="../../assets/logo-sd.png" alt="">
<!--            <img class="name" src="../../assets/images/layout/name.png" alt="">-->
<!--          <div style="background-color:#fff; height: 88px;width: 100px">昇达租房</div>-->
        </div>
        <div class="side-bar-menu">
            <el-menu
                class="menu-class"
                :default-active="activeMenu"
                unique-opened
                background-color="transparent"
                text-color="#fff"
                active-text-color="#fff"
                mode="vertical"
                router
            >
                <template v-for="item in menuList">
                    <el-menu-item
                        v-if="!item.children || item.children.length === 0"
                        :index="item.url"
                        :key="item.id"
                        @click="menuItemClick(item)"
                    >
                        <div class="menu-box flex align-center">
                            <img :src="item.imgSrc ? getUrl(item.imgSrc) : defaultMenuIcon" alt="">
                            <div class="menu-name"> {{ item.name }}</div>
                        </div>
                    </el-menu-item>
                    <el-submenu
                        v-else
                        class="submenu-class"
                        :index="item.url"
                        :key="item.id"
                    >
                        <template slot="title">
                            <div class="menu-box flex align-center">
                                <img :src="item.imgSrc ? getUrl(item.imgSrc) : defaultMenuIcon" alt="">
                                <div class="menu-name"> {{ item.name }}</div>
                            </div>
                        </template>
                        <el-menu-item
                            v-for="child in item.children"
                            :index="`${item.url}/${child.url}`"
                            :key="child.id"
                            route
                            @click="menuItemClick(child)"
                        >
                            {{ child.name }}
                        </el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
        </div>
    </section>
</template>

<script>
import constMenu from "@/assets/json/menu.json";
import {menuCookies, submenuCookies} from "@storage/cookies";

const defaultMenuIcon = require("@images/icon.png");
export default {
    name: "side-bar",
    data() {
        return {
            menuList: [],
            defaultMenuIcon
        };
    },
    computed: {
        activeMenu() {
            const route = this.$route;
            const {meta, path} = route;
            if (meta.activeMenu) return meta.activeMenu;
          // console.log(meta.activeMenu)
            return path;
        },
    },
    created() {
        //this.menuList = constMenu.filter(item => item.id !== 11 && item.id !== 9);
        let menuList = constMenu;
        let menuPathList = JSON.parse(menuCookies.get()).map(({url}) => url);
        let infacePathList = JSON.parse(submenuCookies.get()).map(({url}) => url);

        menuList = menuList.filter(({url}) => menuPathList.some(v => v === url));

        console.log({ constMenu })
        console.log({ menuPathList })
        console.log({ infacePathList })
        console.log({ menuList })

        for (let item of menuList) {
            item.children = item.children.filter(({url}) => infacePathList.some(o => o === url));
        }
        // 2023-10-30-陈守亮修改权限
      this.menuList = this.newrouterfun(menuList)
        // this.menuList = menuList;

      // console.log(this.menuList)
    },
    methods: {
        getUrl(url) {
            return require("@images/layout/" + url);
        },
        menuItemClick(data) {
            let {name, url} = data;
            url = url === '/home' ? 'Home' : url;
            this.$emit('addTab', {title: name, name: url});
        },
      newrouterfun(arr){
        console.log(arr)
       //  处理一级路由
       let arroen =  arr
        console.log(arroen)
        // 处理二级路由
        // arroen = arroen.filter(item => {
        //   if(item.name === '租房管理'){
        //     item.children =  item.children.filter(item => {
        //       if(item.name !== "预约列表" && item.name !== '活动管理' && item.name !== '优惠管理' && item.name !== '领取详情' && item.name !== '综合管理'){
        //           return item
        //       }
        //     })
        //   }
        //   if(item.name === '财务管理'){
        //     item.children =  item.children.filter(item => {
        //       if(item.name !== "票据管理" && item.name !== '租金回笼' && item.name !== '水电统计' && item.name !== '押金统计' && item.name !== '定金统计' && item.name !== '出租情况' && item.name !== '租金统计' && item.name !== '多户支付'){
        //         return item
        //       }
        //     })
        //   }
          // if(item.name === '资产管理') {
          //   item.children = item.children.filter(item => {
          //     if (item.name !== "供应厂商" && item.name !== '资产盘点' && item.name !== '库房管理' ) {
          //       return item
          //     }
          //   })
          // }
        //   return item
        // })
        console.log(arroen)
        return arroen
      }
    }
};
</script>
<style lang="scss" rel="stylesheet/scss">
.el-menu {
    border: none;

    .menu-box {
        img {
            width: VW(17px);
            height: VH(20px);
        }

        .menu-name {
            padding-left: VW(15px);
        }
    }

    .el-menu-item,
    .el-submenu__title {
        padding-left: VW(50px) !important;
        background-color: transparent !important;
    }

    .el-submenu__title {
        height: VH(56px);
        line-height: VH(56px);
    }

    .el-menu-item {
        height: VH(46px);
        line-height: VH(46px);
    }

    .el-menu-item.is-active {
        background: linear-gradient(
                85deg, rgba(165, 171, 255, 0.22) 0%, rgba(31, 36, 106, 0.22) 96%, rgba(25, 30, 99, 0.22) 100%);

    }

    .el-submenu .el-menu-item {
        padding-left: VW(80px) !important;
        text-align: left;
    }

    .el-submenu__title i {
        color: #fff;
    }
}
</style>
<style lang="scss" scoped>
.side-bar {
    overflow: hidden;
    background: url("../../assets/images/layout/side_bg1.png") no-repeat;
    background-size: 100% 100%;

    .side-bar-header {
        height: VH(155px);
        line-height: VH(200px);
        color: #fff;
        font-size: rem(30px);
        font-weight: bold;
        margin-bottom: VH(20px);

        img {
            display: block;
            margin: 0 auto;
            width: VW(100px);
        }
    }
}
</style>
