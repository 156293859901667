import router, { resetRouter } from "./router";
import { asyncRoutes } from "@/router/asyncRoutes";
import store from "./store";
import NProgress from "nprogress";
import * as cookies from "./utils/storage/cookies";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login"];

router.beforeEach(async (to, from, next) => {

    const hasToken = cookies.tokenCookies.get();
    NProgress.start();

    // 白名单
    if (whiteList.includes(to.path)) {
        next();
        return;
    }

    // 未登录或进入登录页，返回登录；
    if (!hasToken) {
        logout(next, to);
        return;
    }

    // 动态添加路由
    const hasRoute = store.getters.gettersRouteFlag;
    if (!hasRoute) {

        try {
            // 设置已添加路由的标记
            await store.dispatch("user/routeFlagActions", true);
            // 动态添加路由
            router.addRoutes(asyncRoutes);
            next({ ...to, replace: true });
        } catch (e) {
            logout(next, to);
            console.error("动态添加路由", e);
        }
        return;
    }

    // 下一步
    next();
});

//
router.afterEach(() => {
    NProgress.done();
});


// 返回登录页
function logout(next, to) {
    next(`/login?redirect=${ to.path }`);
    resetRouter();
    NProgress.done();
}
