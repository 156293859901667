import { tryHideFullScreenLoading } from "../custom/loading";
import messageErrorHint from "./message-error-hint";

export function responseInterceptors(response) {
    const { status, data, config } = response;
    const { resultCode = -1 } = data;
    tryHideFullScreenLoading();
    if (status === 200) {
        // 如果是导出数据流，直接返回数据
        if (config.responseType === "blob") return response.data;

        // 如果是常规数据格式
        let isSuccess = false;
        if (typeof resultCode !== undefined && resultCode === 1000) {
            isSuccess = true;
        }

        if (!isSuccess) {
            // error handle
            messageErrorHint(response, "服务器响应失败");
            return Promise.reject(response);
        }
        // success handle
        return response.data.returnObject;

    } else {
        return Promise.reject(response);
    }
}



