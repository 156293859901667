import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/styles/font.scss";
import "@/assets/styles/index.scss";
import "@/components/Gloabl";
import "@/permission";
import "@/utils/derective/index"

Vue.config.productionTip = false;

Vue.use(ElementUI);

var vue =  new Vue({
    router,
    store,
    render: function (h) {
        return h(App);
    },
}).$mount("#app");
export default Vue 