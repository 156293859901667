import { tokenCookies } from "@storage/cookies";

const state = {
    token: tokenCookies.get(),
    routeFlag: false,
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_ROUTE_FLAG: (state, routeFlag) => {
        state.routeFlag = routeFlag;
        console.log(state.routeFlag)

    },
};

const actions = {
    tokenActions({ commit }, token) {
        commit("SET_TOKEN", token);
    },
    routeFlagActions({ commit }, routeFlag) {
        console.log(routeFlag)
        commit("SET_ROUTE_FLAG", routeFlag);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
