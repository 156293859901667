<template>
    <section class="nav-bar bg-white">
        <div class="right-message">
            <div style="height: 50px;width: 50px;display: flex;justify-content: center;align-items: center;">
                <el-dropdown class="avatar-container" style="cursor: pointer;" trigger="click">
<!--                  2023-22-24-陈守亮-优化消息不纯在隐藏小红点-->
                    <el-badge  :value="msgCount" :max="99" class="item">
                        <i class="el-icon-message-solid"></i>
                    </el-badge>
<!--                  <el-badge  :value="msgCount" :max="99" class="item">-->
<!--                    <i  v-show="msgCount  === 0 " class="el-icon-message-solid"></i>-->
<!--                  </el-badge>-->
                    <el-dropdown-menu slot="dropdown" class="user-dropdown">
                        <el-dropdown-item @click.native="goMessage('')">
                            租房新消息
                            <el-badge :value="zfCount" :max="99" class="item"/>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="right-menu">
            <el-dropdown class="avatar-container" trigger="click">
                <div class="avatar-wrapper flex align-center">
                    <img src="../../assets/images/avatar.png" class="user-avatar">
                    <div class="user-name">{{userName}}</div>
                    <i class="el-icon-caret-bottom"/>
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <el-dropdown-item>修改资料</el-dropdown-item>
                    <el-dropdown-item @click.native="openDialog">修改密码</el-dropdown-item>
                    <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <dialog-user-changepassword ref="dialogUserChangepassword"/>
    </section>
</template>

<script>
    import {resetRouter} from "@/router";
    import {logoutApi, connectApi} from "@/api/login";
    import {outstandingCount} from "@/api/home";
    import {intervalTime} from "@/utils/intervalTime";
    import {tokenCookies, menuCookies, submenuCookies, buttonCookies} from "@storage/cookies";

    export default {
        name: "nav-bar",
        data() {
            return {
                msgCount: 0,
                zfCount: 0,
                spCount: 0,
                userName: "",
            };
        },
        async created() {
            this.keepAlive();
            this.timer = setInterval(() => {
                this.keepAlive();
            }, intervalTime.connectTime);
            let countRes = await outstandingCount();
            this.msgCount = countRes.info;
            let gzfRes = await outstandingCount();
            this.zfCount = gzfRes.info;
            let spRes = await outstandingCount({module: '商铺'});
            this.spCount = spRes.info;
            this.userName = localStorage.getItem("userName");
        },
        components: {
            dialogUserChangepassword: () => import("@/views/system-management/personnel-management/components/dialog-user-changepassword.vue")
        },
        methods: {
            handleLogout() {
                tokenCookies.remove();
                menuCookies.remove();
                submenuCookies.remove();
                buttonCookies.remove();
                localStorage.clear();
                // 退出登录
                logoutApi().then(() => {
                    this.logout();
                });
            },
            keepAlive() {
                // 用于防止session过期，30分钟调取一次接口，该接口无其它意义
                connectApi().then().catch(() => {
                    this.logout();
                });
            },
            logout() {
                tokenCookies.remove();
                menuCookies.remove();
                submenuCookies.remove();
                localStorage.removeItem("userName");
                this.$router.push("/login");
                resetRouter();
                clearInterval(this.timer);
            },
            openDialog() {
                this.$refs["dialogUserChangepassword"].openDialog();
            },
            goMessage(type) {
                this.$router.push({name: "message-reminder", params: {}});
            }
        }
    };
</script>

<style lang="scss" scoped>
    .nav-bar {
        box-shadow: 6px 0 12px rgba(6, 92, 136, 0.16);

        .right-menu {
            position: absolute;
            right: VW(20px);
            top: VH(25px);
            cursor: pointer;

            .user-avatar {
                width: 50px;
                height: 50px;
                opacity: 1;
                border-radius: 50%;
                background-color: #AEFFFE;
            }

            .user-name {
                color: #333333;
                font-size: rem(18px);
                font-weight: 600;
                padding: 0 VW(15px);
                font-family: 'PingFang SC Semibold';
            }
        }

        .right-message {
            position: absolute;
            right: VW(205px);
            top: VH(25px);
        }
    }
</style>
