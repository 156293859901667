<template>
    <el-dialog
            v-bind="$attrs"
            :center="center"
            :visible.sync="dialogVisible"
            class="r-e-dialog"
            :destroy-on-close="destroyOnClose"
            :append-to-body="appendToBody"
            :close-on-click-modal="closeOnClickModal"
            :before-close="handleClose"
            v-if="dialogVisible"
            :fullscreen="fullscreen"
    >
        <slot v-if="dialogVisible"></slot>
        <div class="submit-btn-group" slot="footer" v-if="showFooter">
            <el-button class="cancel" @click="$emit('click-cancel', 'cancel')">
                {{ footerBtnName[0] }}
            </el-button>
            <el-button class="submit" @click="$emit('click-submit')">
                {{ footerBtnName[1] }}
            </el-button>
            <slot name="footer"></slot>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            center: {
                type: Boolean,
                default: true,
            },
            visible: {
                type: Boolean,
                default: false,
            },
            destroyOnClose: {
                type: Boolean,
                default: true,
            },
            appendToBody: {
                type: Boolean,
                default: true,
            },
            closeOnClickModal: {
                type: Boolean,
                default: false,
            },
            showFooter: {
                type: Boolean,
                default: false,
            },
            footerBtnName: {
                type: Array,
                default: () => ['取消', '确定'],
            },
            //是否全屏
            fullscreen: {
                type: Boolean,
                default: false,
            }
        },
        name: 'r-e-dialog',
        computed: {
            dialogVisible: {
                get() {
                    return this.visible;
                },
                set(val) {
                    this.$emit('update:visible', val);
                },
            },
        },
        methods: {
            handleClose(done) {
                this.$emit('click-cancel', 'cancel');
                done();
            },
        },
    };
</script>

<style lang="scss" rel="stylesheet/scss">
    .r-e-dialog.el-dialog__wrapper {
        .el-dialog {
            border-radius: 8px;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

            .el-dialog__header {
                color: #333;
                border-radius: 8px 8px 0 0;
                padding: VH(25px) VW(10px);
                border-bottom: 1px solid #F1F1F3;

                .el-dialog__title {
                    font-size: rem(25px);
                    font-family: PingFang SC Semibold;
                    font-weight: 600;
                }
            }

            .el-dialog__footer {
                padding-top: VH(20px);
                border-top: 1px solid #F1F1F3;
            }
        }

        .submit-btn-group {
            .el-button {
                font-size: rem(14px);
                padding: VH(10px) VW(30px);
                border: none;
                border-radius: 4px;
                box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

                &.cancel {
                    color: #3791F7;
                    background: #CEE7FF;
                }
                &.submit {
                    color: #fff;
                    background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                }
            }
        }
    }
</style>
