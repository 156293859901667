import Cookies from 'js-cookie'

// token
const tokenCookies = {
    set(token) { Cookies.set('Authorization', token) },
    get: () => Cookies.get('Authorization'),
    remove: () => Cookies.remove('Authorization')
}

// menu 菜单权限
const menuCookies = {
    set(menu) { localStorage.setItem('menu', menu) },
    get: () => localStorage.getItem('menu'),
    remove: () => localStorage.removeItem('menu')
}
// submenu 界面权限
const submenuCookies = {
    set(submenu) {localStorage.setItem('submenu', submenu)},
    get: () => localStorage.getItem('submenu'),
    remove: () => localStorage.removeItem('submenu')
}
// button 按钮权限
const buttonCookies = {
    set(button) { localStorage.setItem('button', button) },
    get: () => localStorage.getItem('button'),
    remove: () => localStorage.removeItem('button')
}
export {
    tokenCookies,
    menuCookies,
    submenuCookies,
    buttonCookies
}
